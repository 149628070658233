<template>
    <div @keydown.escape="$emit('close')"  class="absolute inline-block text-left">
        <transition
                enter-class="transform opacity-0 scale-95"
                enter-active-class="transition ease-out duration-100"
                enter-to-class="transform opacity-100 scale-100"
                leave-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-to-class="transform opacity-0 scale-95"
        >
            <div v-if="open && !usersEmpty" v-on-clickaway="close" class="absolute -mt-2 w-full rounded-md bg-white shadow-lg">
                <ul tabindex="-1" role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-item-3"
                    class="max-h-56 rounded-md py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5">
                    <li v-for="user in users" :key="user.JID" @click="userSelected(user)" id="listbox-item-0" role="option"
                        class="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9 hover:text-white hover:bg-indigo-600">
                        <div class="flex items-center space-x-3">
                            <img :src="user.avatarUrl" alt="" class="flex-shrink-0 h-6 w-6 rounded-full">
                            <!-- Selected: "font-semibold", Not Selected: "font-normal" -->
                            <span class="font-normal block truncate">
                              {{ user.firstName + " " + user.lastName }}
                                <span class="text-gray-400"> @{{ user.username }}</span>
                            </span>
                        </div>
                    </li>
                </ul>
            </div>
        </transition>
    </div>
</template>

<script>
    import {directive as onClickaway} from 'vue-clickaway'
    export default {
        name: "UserSelection",
        props:['open', 'users'],
        directives: {
            onClickaway
        },
        computed: {
            usersEmpty(){
                return this.users.length === 0
            }
        },
        methods: {
            userSelected(user){
                this.$emit('userSelected', user)
                this.close()
            },
            close(){
                this.$emit('close')
            }
        }
    }
</script>

<style scoped>

</style>
